import dayjs from 'dayjs';
import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';

const ur = {
  studentId: null, // optional field - used by non-student users. students will go by php session ID
  ttSpecifics: {},
  // tt specifics should be loaded for how many months/ weeks/ years? starting from latest submission
  loadInterval: {
    duration: '2w',
  }, // { duration: '2w', start: YYYY-MM-DD, end: YYYY-MM-DD }
}

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    studentId(state) { return state.studentId; },
    ttSpecifics(state) { return state.ttSpecifics; },
    loadInterval(state) { return state.loadInterval; },
  },
  actions: {
    // pl: { testTypeId: 123, userId?: (optional - if not set, the server will use the session's user_id) },
    fetchTtSpecific(context, pl) { 

      let payload = { testTypeId: pl.testTypeId, };
      if (pl.userId) {
        payload.userId = pl.userId;
        context.commit('setStudentId', payload.userId); // payload contained a userId. remember for later calls

      } else if (context.getters.studentId != null) {
        payload.userId = context.getters.studentId;
      }

      const url = context.rootGetters['auth/loginBaseUrl'];

      return new Promise((resolve, reject) => {

        ax(url + 'sscore/fetchttbasic', payload).then(
          (basicRes) => {

            const li = context.getters.loadInterval;
            let end = li.end;
            let start = li.start;
            let loadmode = 'dates';
            
            if (li.duration !== undefined) {
              const latestStart = basicRes.data.latest_start.split(" ")[0];
      
              const subNum = parseInt(li.duration.slice(0, -1));
              let subUnit = li.duration.slice(-1).toString();
              if (subUnit === 'm') subUnit = 'M'; // week w, month M, year y
      
              end = dayjs(latestStart)
                .format('YYYY-MM-DD');
              start = dayjs(latestStart)
                .subtract(subNum, subUnit)
                .format('YYYY-MM-DD');
      
              loadmode = 'duration';
            } 
            payload.start = start;
            payload.end = end;

          
            ax(url + 'sscore/fetchttspecific', payload)
              .then(
                (response) => {

                  response.data.basic_data = {
                    ...response.data.basic_data,
                    ...basicRes.data,
                  }; // add basicRes into this data

                  const savePayload = {
                    testTypeId: pl.testTypeId,
                    data: response.data,
                  };
                  context.commit('setTtSpecifics', savePayload);
                  resolve(loadmode);
                },
                (error) => {
                  co("error", "fetchTtSpecific", error);
                  reject(error);
                }
            );
          

          },
          (basicErr) => {
            co("error", "fetchTtSpecific", basicErr);
            reject(basicErr);
          }
        );
      });

    },
    setLoadInterval(context, pl) {
      context.commit('setLoadInterval', pl);
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    setStudentId(state, pl) {
      state.studentId = pl;
    },
    setTtSpecifics(state, pl) {
      state.ttSpecifics[pl.testTypeId] = pl.data;
    },
    setLoadInterval(state, pl) {
      state.loadInterval = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
}