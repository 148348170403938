import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';

const ur = {
  qtypes: {},
  loadStarted: [], // ttids that have been requested for fetch - avoid concurrent loads
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    qtypes(state) {
      return state.qtypes;
    },
    loadStarted(state) {
      return state.loadStarted;
    },
  },
  actions: {
    loadQtypes(context, ttid) {

      return new Promise((resolve, reject) => {
        if (context.getters.qtypes[ttid] !== undefined) {
          resolve();
        } else {
          const url = context.rootGetters['auth/loginBaseUrl'];
          if (context.getters["loadStarted"].includes(parseInt(ttid))) {
            resolve();
            return;
          }
          context.commit("addToLoadStarted", ttid);
          ax(url + 'tdata/loadqt', { ttid: ttid, }).then(
            (response) => {
              if (response.data.qtypes) {
                context.commit('setQtypes', {
                  ttid: ttid,
                  qtypes: response.data.qtypes,
                });
                context.commit("removeFromLoadStarted", ttid);
              }
              resolve();
            },
            (error) => { 
              reject(error); 
              co("error", "loadQtypes", error);
            }
          );
        }
      });
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setQtypes(state, pl) {
      state.qtypes[pl.ttid] = {};
      for (const qt of pl.qtypes) {
        state.qtypes[pl.ttid][qt.id] = qt;
      }
    },
    addToLoadStarted(state, ttid) {
      if (!state.loadStarted.includes(ttid))
        state.loadStarted.push(parseInt(ttid));
    },
    removeFromLoadStarted(state, ttid) { 
      const idx = state.loadStarted.findIndex(el => el === parseInt(ttid));
      state.loadStarted.splice(idx, 1);
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};