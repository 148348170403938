export function validateEmailAddress(str) {
  // eslint-disable-next-line
  const matches = str.match(/(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi);
  
  // matches will contain an array of strings matching the pattern. ergo matches[0] needs to match the input string
  if (!matches)
    return false;
  return matches[0] == str;
}

export function colorizePerformance(val) {
  val = parseInt(val);
  if (val < 60) return '#92949c'; // medium
  if (val < 90) return '#3dc2ff'; // secondary
  return '#3880ff'; // primary
}

export function getFgFromBgColor(bgColorString) {
  if (bgColorString.length === 6) 
    bgColorString = '#' + bgColorString;

  const red = parseInt(bgColorString.substr(1, 2), 16);
  const green = parseInt(bgColorString.substr(3, 2), 16);
  const blue = parseInt(bgColorString.substr(5, 2), 16);

  let fgColorString = '#FFFFFF';
  if ((red*0.299 + green*0.587 + blue*0.114) > 186) 
    fgColorString = '#000000';
  
  return { fg: fgColorString, bg: bgColorString, };
}

export function pixelFromVH(vh) {
  return document.documentElement.clientHeight * (vh / 100);
}

export function objIsEmpty(obj) {
  for (var i in obj) return false;
  return true;
}

export function debounce (callback, wait) {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

export function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {  // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {  // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {  // IE?
    document.selection.empty();
  }
}

// the favicon file has to be located in /public/assets/icon
export function setFavicon(filename) {
  if (filename.length === 0)
    return;

  // Get or create the favicon
  let link = document.querySelector("link[rel*='icon']");
  if (!link) {
    document.createElement('link')
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    document.head.appendChild(link);
  }
  link.href = './assets/icon/' + filename;
}

export function randomProperty(obj) {
  var keys = Object.keys(obj);
  return obj[keys[ keys.length * Math.random() << 0]];
}