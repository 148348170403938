import { 
  create, 
  createOutline,
  logoReact,
  book,
  bookOutline,
  reader,
  readerOutline,
  personAdd,
  trendingUp,
  personCircle, 
  cloudUpload,
  server,
  cog,
} from 'ionicons/icons';

export default {
  data() {
    return {
      student: {
        menus: [
          {
            id: "menu-tests",
            title: "Test Menu",
            items: [
              {
                disabled: true,
                title: "Tests",
                icon: create,
              },
              {
                title: "Unanswered",
                link: "/main/tl/testopen",
              },
              {
                title: "Results",
                link: "/main/tl/testresult",
              },      
              {
                disabled: true,
                title: "Workbooks",
                icon: book,
              },      
              {
                title: "Open",
                link: "/main/tl/wbopen",
              },
              {
                title: "Results",
                link: "/main/tl/wbresult",
              },
              {
                disabled: true,
                title: "Level Tests",
                icon: personAdd,
              },   
              {
                title: "Level Tests",
                link: "/main/tl/entrance",
              }, 
            ],
          },
          {
            id: "menu-vocab",
            title: "Vocab",
            items: [
              {
                disabled: true,
                title: "Vocab Tests",
                icon: readerOutline,
              },
              {
                title: "Take Tests",
                link: "/main/vl/totake",
              },
              {
                title: "Results",
                link: "/main/vl/results",
              },      
            ],
          },
          {
            id: "menu-me",
            title: "Me",
            items: [
              {
                disabled: true,
                title: "My Page",
                icon: personCircle,
              },
              {
                title: "Me",
                link: "/main/me",
              },
              {
                disabled: true,
                title: "About",
                icon: cog,
              },
              {
                title: "About",
                link: "/main/about",
              },
            ],
          },
        ],
      }, // end student
      manager: {
        tabs: [
          {
            tab: 'testlist',
            title: 'Test List',
            href: '/main/mng/testlist',
            icon: server,
          },
          {
            tab: 'importrw',
            title: 'Import RW',
            href: '/main/mng/importrw',
            icon: cloudUpload,
          },
          {
            tab: 'importmath',
            title: 'Import Math',
            href: '/main/mng/importmath',
            icon: cloudUpload,
          },
          {
            tab: 'manager',
            title: 'Me',
            href: '/main/me',
            icon: personCircle,
          },
        ],
        menus: [
        ],
      }, // end manager
      teacher: {
        tabs: [
          {
            tab: 'testlist',
            title: 'Test List',
            href: '/main/tch/testlist',
            icon: server,
          },
          {
            tab: 'wblist',
            title: 'WB List',
            href: '/main/tch/wblist',
            icon: book,
          },
          {
            tab: 'manager',
            title: 'Me',
            href: '/main/me',
            icon: personCircle,
          },
        ],
        menus: [
        ],
      }, // end teacher

    };
  }, // data
  computed: {
    routePath() { return this.$route.path; },
    studentTabs() {
      let testObj = {
        tab: 'testopen',
        title: 'Take Tests',
        href: '/main/tl/testopen',
        icon: createOutline,
      };
      if (this.$route.path === testObj.href) {
        testObj.title = 'T-Res';
        testObj.href = '/main/tl/testresult';
        testObj.icon = create;
      }
      let personalObj = {
        tab: 'personalopen',
        title: 'Personal',
        href: '/main/tl/personal',
        icon: logoReact,
      };
      let wbObj = {
        tab: 'wbopen',
        title: 'Workbook',
        href: '/main/tl/wbopen',
        icon: bookOutline,
      };
      if (this.$route.path === wbObj.href) {
        wbObj.title = 'WB-Res';
        wbObj.href = '/main/tl/wbresult';
        wbObj.icon = book;
      }
      let vocabObj = {
        tab: 'vocab',
        title: 'Vocab',
        href: '/main/vl/totake',
        icon: readerOutline,
      };
      if (this.$route.path === vocabObj.href) {
        vocabObj.title = 'V-Res';
        vocabObj.href = '/main/vl/results';
        vocabObj.icon = reader;
      }
      return [
        testObj,
        personalObj,
        wbObj,
        vocabObj,
        {
          tab: 'stats',
          title: 'Stats',
          href: '/main/stats',
          icon: trendingUp,
        },
        {
          tab: 'me',
          title: 'Me',
          href: '/main/me',
          icon: personCircle,
        },
      ];
    },

    myMainTabs() {
      if (this.$store.getters['me/isManager']) 
        return this.manager.tabs;
      else if (this.$store.getters['me/isTeacher'])
        return this.teacher.tabs;
      return this.studentTabs;
    },
    myMainMenus() {
      if (this.$store.getters['me/isManager']) 
        return this.manager.menus;
      else if (this.$store.getters['me/isTeacher'])
        return this.teacher.menus;
      return this.student.menus;
    },
  },
};