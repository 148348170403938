// import { co } from '@/assets/diagnostics.js';
import { LocalStorage as ls } from '@/assets/localStorage.js';

const CURRENT_ANSWERS_LOCAL_FIELDNAME = 'sdk_sc_curans';
const ur = {
  scheduledTestId: 0,
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    scheduledTestId(state) {
      return state.scheduledTestId;
    },
    currentTestLocalAnswers(state, getters, rootState, rootGetters) {
      if (process.env.VUE_APP_AUTOSAVE_TEST_LOCAL !== "1")
        return false;
      const localData = ls.getDataRowForUser(
        CURRENT_ANSWERS_LOCAL_FIELDNAME,
        rootGetters["me/id"],
        state.scheduledTestId);
      if (localData == undefined) {
        return false;
      }
      return localData;
    },
  },
  actions: {
    setScheduledTestId(context, pl) {
      context.commit("setScheduledTestId", pl);
    },
    saveTestAnswersLocally(context, pl) {
      if (process.env.VUE_APP_AUTOSAVE_TEST_LOCAL !== "1")
        return false;
      ls.setDataRowForUser(
        CURRENT_ANSWERS_LOCAL_FIELDNAME,
        context.rootGetters["me/id"],
        context.getters["scheduledTestId"],
        pl
      );
    },
    deleteTestAnswersLocally(context, pl) { // pl : scheduledTestId
      ls.deleteDataRowForUser(
        CURRENT_ANSWERS_LOCAL_FIELDNAME, 
        context.rootGetters["me/id"], 
        pl
      );
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setScheduledTestId(state, pl) {
      state.scheduledTestId = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};