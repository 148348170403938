import { parseFairlightCookie } from "@/assets/CookieFunctions.js";
import { userLevels } from "@/constants/users.js";

const ur = {
  firstname: '',
  lastname: '',
  email: '',
  id: 0,
  level: 0,
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    name(state) {
      return {
        firstname: state.firstname,
        lastname: state.lastname,
      };
    },
    email(state) {
      return state.email;
    },
    id(state) {
      return state.id;
    },
    level(state) {
      return state.level;
    },
    isStudent(state) {
      return state.level === userLevels.student;
    },
    isTeacher(state) {
      return state.level === userLevels.teacher;
    },
    isGuardian(state) {
      return state.level === userLevels.guardian;
    },
    isManager(state) {
      return state.level >= userLevels.manager_lo;
    },
  },
  actions: {
    setMeDataFromCookie(context) {
      const parseRes = parseFairlightCookie();

      if (parseRes !== false) {
        context.commit("setName", {
          firstname: parseRes.firstname,
          lastname: parseRes.lastname,
        });
        context.commit("setId", parseRes.user_id);
        context.commit("setEmail", parseRes.email);
        context.commit("setLevel", parseRes.user_level);
      }
    },
    setName(context, payload) {
      context.commit('setName', payload);
    },
    setEmail(context, payload) {
      context.commit('setEmail', payload);
    },
    setId(context, payload) {
      context.commit('setId', payload);
    },
    setLevel(context, payload) {
      context.commit('setLevel', payload);
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    setName(state, payload) {
      state.firstname = payload.firstname;
      state.lastname = payload.lastname;
    },
    setEmail(state, payload) {
      state.email = payload;
    },
    setId(state, payload) {
      state.id = payload;
    },
    setLevel(state, payload) {
      state.level = parseInt(payload);
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};