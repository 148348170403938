import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';

const ur = {
  /**
   * students: {
   *  userId: {
   *    encoded ( mobile, email, custom_id, firstname, nickname, lastname, )
   *  },
   * },
   */
  students: {},
};
export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    students(state) { return state.students; },
  },
  actions: {
    fetchStudent(context, pl) { // pl: { studentId: , force?: bool, }
      if (!pl.studentId) return false;
      if (context.getters.students[pl.studentId] != undefined &&
        !pl.force
      ) {
        return false;
      }

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'user/getstudent', pl).then(
          (response) => { 
            context.commit('addStudent', {
              studentId: pl.studentId,
              data: response.data,
            });
            resolve();
          },
          (error) => { 
            reject(error); 
            co('danger', error)
          },
        );
      });
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    addStudent(state, pl) {
      state.students[pl.studentId] = pl.data;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};
