import { ax } from '@/assets/axios.js';
import { objIsEmpty } from '@/assets/utils.js';
import { co } from '@/assets/diagnostics.js';

const ur = {
  ttTaken: {},
  ttTakenWorkbook: {},
}

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    ttTaken(state) { return state.ttTaken; },
    ttTakenWorkbook(state) { return state.ttTakenWorkbook; },
  },
  actions: {
    // pl: {forceReload}
    fetchTtData(context, pl) { 
      if (pl?.forceReload !== true &&
        ( !objIsEmpty(context.getters.ttTaken) || 
          !objIsEmpty(context.getters.ttTakenWorkbook) )
      )
        return;

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'sscore/fetchttdata').then(
            (response) => {
              context.commit('setTTTaken', response.data.tt_taken);
              context.commit('setTTTakenWorkbook', response.data.tt_taken_wb);
              resolve();
            },
            (error) => {
              co("error", "fetchTtData", error);
              reject(error);
            }
        );
      });
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    setTTTaken(state, pl) { // pl: array of test type data
      for (const tt of pl) {
        state.ttTaken[tt.id] = tt;
      }
    },
    setTTTakenWorkbook(state, pl) { // pl: array of test type data
      for (const tt of pl) {
        state.ttTakenWorkbook[tt.id] = tt;
      }
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
}