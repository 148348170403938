import { createRouter, createWebHistory } from '@ionic/vue-router';
import { checkLoggedIn } from "@/router/guards.js";
import { studentTabs, studentPages } from "./student/index.js";
import { teacherTabs, teacherPages } from "./teacher/index.js";
import { erpTabs, erpPages } from "./erp/index.js";
import { testRoutes } from "./ztest/index.js";

import HomePage from '../pages/HomePage.vue';
import MainTabs from '../components/ui/MainTabs';

let routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    component: () => import('../pages/LoginPage.vue'),
  },
  {
    path: '/crosslogin',
    component: () => import('../pages/CrossLoginPage.vue'),
  },
  {
    path: '/main/',
    component: MainTabs,
    beforeEnter: [checkLoggedIn],
    children: [
      {
        path: '',
        component: () => import('@/pages/TabTrafficGuide.vue'),
      },
      {
        path: 'me',
        beforeEnter: [checkLoggedIn],
        component: () => import('@/pages/tabs/MeTab.vue'),
      },
    ],
  }, 

  {
    path: '/testimport',
    component: () => import('@/pages/_tools/TestImport.vue'),
  },
  {
    path: '/restricted',
    component: () => import('@/pages/RestrictedPage.vue'),
  },
  
]

// add paths depending on app target
const tabsIdx = routes.findIndex(r => r.path === '/main/');

if (process.env.VUE_APP_TARGET === "student") {
  routes[tabsIdx].children = routes[tabsIdx].children.concat(studentTabs);
  routes = routes.concat(studentPages);
}

else if (process.env.VUE_APP_TARGET === "teacher") {
  routes[tabsIdx].children = routes[tabsIdx].children.concat(teacherTabs);
  routes = routes.concat(teacherPages);
}

else if (process.env.VUE_APP_TARGET === "erp") {
  routes[tabsIdx].children = routes[tabsIdx].children.concat(erpTabs);
  routes = routes.concat(erpPages);
}

if (process.env.VUE_APP_TESTPAGES == "1") {
  routes = routes.concat(testRoutes);
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
