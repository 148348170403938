// import Axios from 'axios-observable';
import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
// import { decodeBackend } from '@/assets/encode.js';
import { testLists } from '@/constants/testlists.js';

// const ax = (url, pl) => {
//   return new Promise((resolve, reject) => {
//     Axios.defaults.withCredentials = true;
//     Axios.post(url, pl)
//       .subscribe({
//         next: (response) => { resolve(response); },
//         error: (error) => { reject(error); },
//     });
//   });
// };

export default {
  namespaced: true,
  state() {
    return {
      
      // take tests (digital style) MOVED TO testtake
      // loadedTest: null,
      // takeScheduledTestId: 0, 
      // takeTestSetId: 0,
      
      // submit tests (answer input only style)   MOVED TO testtake
      // tsubTestSet: null,
      // tsubQuestions: [],
      // tsubScheduledTest: null,

      // student answers (for viewing test result)

      // standard test lists
      testopen: [],
      testresult: [],
      personal: [],
      wbopen: [],
      wbresult: [],

      searched: [],

      // entrance test list
      entrance: [],
    };
  },
  getters: {
    // loadedTest(state) { return state.loadedTest; },
    // takeScheduledTestId(state) { return state.takeScheduledTestId; },
    // takeTestSetId(state) { return state.takeTestSetId; },

    // tsubTestSet(state) { return state.tsubTestSet; },  MOVED to testtake!
    // tsubQuestions(state) { return state.tsubQuestions; },
    // tsubScheduledTest(state) { return state.tsubScheduledTest; },
    

    testopen(state) { return state.testopen; },
    testresult(state) { return state.testresult; },
    personal(state) { return state.personal; },
    wbopen(state) { return state.wbopen; },
    wbresult(state) { return state.wbresult; },
    searched(state) { return state.searched; },
    entrance(state) { return state.entrance; },
  },
  actions: {
    setTakeScheduledTestId(context, pl) {
      context.commit('setTakeScheduledTestId', pl);
    },
    setTakeTestSetId(context, pl) {
      context.commit('setTakeTestSetId', pl);
    },
    // pl: { listname: , forceReload: Bool, concat: Bool, offset: ,  
    //   ... , 
    loadTestsForStudent(context, pl) {
      let listId = pl.listname;

      let concat = false; // infinite scroller?
      if (pl.concat !== undefined && pl.concat === true)
        concat = true;

      if (!pl.forceReload && !concat && context.getters[listId].length > 0)
        return false;

      let isWorkbook = 0;
      const wbIdx = testLists.findIndex(el => el.id == listId);
      if (testLists[wbIdx].wb === true)
        isWorkbook = 1;

      let loadParams = { isWorkbook: isWorkbook, };
      if (pl.offset !== undefined)
        loadParams.offset = pl.offset;

      // scheduledTestType: [] // 1 - standard, 2 - retake, 4 - remix
      loadParams.scheduledTestTypes = [1, 4];

      // determine the time period to load
      loadParams.period = 'pp2d'; // default: 'pp2d' : past plus 2 days : all records from the past up til start date of current date plus 2 days 

      let urlSuffix = 'stest/load';
      if (listId === 'testopen') {
        loadParams.hasAnswers = 0;
      } else if (listId === 'wbopen') {
        loadParams.hasAnswers = 0;
      } else if (listId === 'testresult') {
        loadParams.hasAnswers = 1;
      } else if (listId === 'wbresult') {
        loadParams.hasAnswers = 1;
      } else if (listId === 'entrance') {
        loadParams = {};
        urlSuffix = 'stest/loadopen'
      } else if (listId === 'personal') {
        loadParams.personalized = true;
        loadParams.scheduledTestTypes = [2, 4];
      }

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];

        // Axios.defaults.withCredentials = true;
        // Axios.post(url + urlSuffix, loadParams)
        //   .subscribe({
        //     next: (response) => {
        //       context.commit('setStudentTestList', {
        //         listId: listId,
        //         data: response.data.test_data,
        //         concat: concat,
        //       });
        //       resolve(response.data);
        //     },
        //     error: (error) => {
        //       reject(error);
        //     }
        // });

        ax(url + urlSuffix, loadParams).then(
          (response) => { 
            context.commit('setStudentTestList', {
              listId: listId,
              data: response.data.test_data,
              concat: concat,
            });
            resolve(response.data);
          },
          (error) => { 
            co('danger', error); 
            reject(error);
          },
        );

      });
    },
    searchTests(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'stest/search', pl).then(
          (response) => { 
            context.commit('setStudentTestList', {
              listId: 'searched',
              data: response.data.test_data,
            });
            resolve(response);
          },
          (error) => { 
            co('danger', error); 
            reject(error); 
          },
        );
      });
    },
    // loadForSubmit(context, scheduledTestId) {  MOVED to testtake
    //   const existingScheduledTestId = context.getters.tsubScheduledTest?.id;
    //   if (scheduledTestId == existingScheduledTestId) {
    //     return;
    //   }

    //   return new Promise((resolve, reject) => {
    //     const url = context.rootGetters['auth/loginBaseUrl'];
    //     const pl = {
    //       scheduledTestId: scheduledTestId,
    //     };

    //     Axios.defaults.withCredentials = true;
    //     Axios.post(url + 'stake/loadforsubmit', pl)
    //       .subscribe({
    //         next: (response) => {
    //           context.commit('setTsubTestSet', decodeBackend(response.data.test_set_data));
    //           context.commit('setTsubQuestions', decodeBackend(response.data.question_data));
    //           context.commit('setTsubScheduledTest', decodeBackend(response.data.basic_test_data));
    //           resolve();
    //         },
    //         error: (error) => {
    //           reject(error);
    //         }
    //     });
    //   });
    // },
    // loadTest(context, pl) {
    //   return new Promise((resolve, reject) => {
    //     const url = context.rootGetters['auth/loginBaseUrl'];
    //     let out = { tid: pl.tid, };

    //     // only add noanswers if the value is FALSE
    //     // we want no visible payload if we do hide answers - just in case
    //     if (pl.showanswers) 
    //       out.unsanitized = 1; 

    //     Axios.defaults.withCredentials = true;
    //     Axios.post(url + 'tdata/loadtest', out)
    //       .subscribe({
    //         next: (response) => {
    //           if (response.data.enc) {
    //             context.commit('setLoadedTest', decodeBackend(response.data.enc));
    //           }
    //           resolve();
    //         },
    //         error: (error) => {
    //           reject(error);
    //         }
    //     });
    //   });
    // },

    // pl: {takeScheduledTestId: , started: ,  },
    checkTestStarted(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'stake/checkstarttime', pl).then(
          (response) => { 
            resolve(response.data);
          },
          (error) => { 
            co('danger', error); 
            reject(error); 
          },
        );
      });
    },
    resetAll(context) {
      // context.commit('setTakeScheduledTestId', 0);
      // context.commit('setTakeTestSetId', 0);
      // context.commit('setLoadedTest', null);

      // context.commit('setTsubTestSet', null);
      // context.commit('setTsubQuestions', []);
      // context.commit('setTsubScheduledTest', null);


      for (const list of testLists) {
        context.commit('setStudentTestList', {
          listId: list.id,
          data: [],
        });
      }
    },
  },
  mutations: {
    // setTakeScheduledTestId(state, pl) { state.takeScheduledTestId = parseInt(pl); },
    // setTakeTestSetId(state, pl) { state.takeTestSetId = pl; },
    // setLoadedTest(state, pl) { state.loadedTest = pl; },

    // setTsubTestSet(state, pl) { state.tsubTestSet = pl; },
    // setTsubQuestions(state, pl) { state.tsubQuestions = pl; },
    // setTsubScheduledTest(state, pl) { state.tsubScheduledTest = pl; },

    // pl { listId: , data: , }
    setStudentTestList(state, pl) {
      if (pl.concat) {

        // make sure no duplicates are added
        for (const scheduledTest of pl.data) {
          const idx = state[pl.listId].findIndex(el => el.scheduled_test_id == scheduledTest.scheduled_test_id);
          if (idx === -1) {
            state[pl.listId].push(scheduledTest);
          }
        }

      } else {
        state[pl.listId] = pl.data;
      }

    },
  },
};