export const userLevels = {
  student: 1,
  guardian: 2,
  teacher: 8,
  manager_lo: 16,
  manager_hi: 32,
  director: 64,
};

/**
 * the following is needed to figure out which userlevels 
 * should be allowed to use the "forgot password" function
 * from each build target
 */
export const userLevelsForTarget = {
  'student': [ userLevels['student'], ],
  'teacher': [ userLevels['teacher'], ],
  'erp': [ 
    userLevels['manager_lo'], 
    userLevels['manager_hi'], 
    userLevels['director'], 
  ],
};
