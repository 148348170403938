<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-icon v-if="routerPop" 
            class="clickable"
            :icon="backspace" 
            @click="popRoute">
          </ion-icon>
          <ion-icon v-if="menuId !== ''" 
            class="clickable"
            :icon="menuIcon" 
            @click="openMenu">
          </ion-icon>
        </ion-buttons>
        <ion-title v-if="pageTitle !== ''">{{ pageTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot></slot>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonContent,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonIcon,
  menuController,
} from "@ionic/vue";
import { menu, settingsOutline, backspace } from "ionicons/icons";

export default {
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonIcon,
  },
  props: {
    pageTitle: {
      type: String,
      required: false,
      default: '',
    },
    menuIconName: {
      type: String,
      required: false,
      default: 'menu',
    },
    menuId: {
      type: String,
      required: false,
      default: '',
    },
    routerPop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      menu,
      settingsOutline,
      backspace,
    };
  },
  computed: {
    menuIcon() { 
      if (this.menuIconName === 'settings') return this.settingsOutline; 
      return this.menu;
    },
  },
  methods: {
    openMenu() {
      menuController.enable(true, this.menuId);
      menuController.open(this.menuId);
    },
    popRoute() {
      this.$router.back();
    },
  },
};
</script>