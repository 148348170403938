import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
import { percToScaled, } from "@/assets/scaled.js";

const ur = {
  requestedTtids:[], // keep track of which ttids have been requested regardless of whether retrieval is complete yet
  queuedPercentages: [], // if a lookup is denied because the ttid is already requested, keep track of which percentage value is requested
  scaledData: {},
  lookedUp: {},
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    requestedTtids(state) { return state.requestedTtids; },
    queuedPercentages(state) { return state.queuedPercentages; },
    scaledData(state) { return state.scaledData; },
    lookedUp(state) { return state.lookedUp },
  },
  actions: {
    // pl: { ttid, perc }
    lookup(context, pl) {
      return new Promise((resolve, reject) => {

        if (context.getters.scaledData[pl.ttid] !== undefined) {
          context.dispatch("calculate", pl);
          resolve();
          return;
        }
        
        if (context.getters.requestedTtids.includes(pl.ttid)) {
          context.commit("addQueuedPercentage", pl);
          // context.dispatch("calculate", pl); // not needed probably(!)
          resolve();
          return;
        }

        context.commit("addRequestedTtid", pl.ttid);
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'scaled/loadscaled', { ttid: pl.ttid, }).then(
          (response) => {
            if (response.data.scaled_data) {
              context.commit('addScaledData', response.data.scaled_data);
              context.dispatch("calculate", pl);
              resolve();
            } else {
              reject('expected scaled data not returned');
            }
          },
          (error) => { 
            co("error", "lookup", error);
            reject(error); 
          }
        );
        
      });
    },
    // pl: { ttid, perc }
    calculate(context, pl) {

      // return value determines whether a queued item has been processed and should be deleted or not.
      const executeCalc = (ttid, perc) => {

        if (context.getters.scaledData[ttid]?.data == undefined)
          return false; // necessary data not available.

        if (context.getters.lookedUp[ttid] != undefined) {
          const idx = context.getters.lookedUp[ttid].findIndex(el => el.perc == perc);
          if (idx > -1)
            return true; // already looked up!
        }
  
        const scaled = percToScaled(perc, context.getters.scaledData[ttid].data);
        if (scaled === -1)
          return true;
        
        context.commit("addLookedUp", {
          scaled,
          ttid,
          perc,
        });

        return true;
      };

      executeCalc(pl.ttid, pl.perc);
      // co('dark', 'calculate directly - success:', success, pl.ttid, pl.perc);

      for (let i=context.getters.queuedPercentages.length - 1; i >= 0; i--) {
        const tmp = context.getters.queuedPercentages[i];
        const processed = executeCalc(tmp.ttid, tmp.perc);
        // co('dark', 'calculate queued - processed:', processed, tmp.ttid, tmp.perc);
        if (processed) {
          context.commit("spliceFromQueuedPercentages", i);
        }
      }
    },
  },
  mutations: {
    addRequestedTtid(state, pl) {
      state.requestedTtids.push(pl);
    },
    addQueuedPercentage(state, pl) { // pl: {ttid, perc},
      const qIdx = state.queuedPercentages.findIndex(el => {
        return el.ttid == pl.ttid && el.perc == pl.perc;
      });
      if (qIdx === -1) 
        state.queuedPercentages.push(pl);
    },
    spliceFromQueuedPercentages(state, pl) { // pl = splice index
      if (state.queuedPercentages[pl] != undefined)
        state.queuedPercentages.splice(pl, 1);
    },
    addScaledData(state, pl) { // pl:
      pl.data = JSON.parse(pl.json_data);
      delete pl.json_data;
      state.scaledData[pl.test_type_id] = pl;
    },
    addLookedUp(state, pl) { // pl: { ttid, perc, scaled }
      if (state.lookedUp[pl.ttid] == undefined)
        state.lookedUp[pl.ttid] = [];
      state.lookedUp[pl.ttid].push({ perc: pl.perc, scaled: pl.scaled });
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};