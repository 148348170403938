import { fabric } from "fabric-with-erasing";

export const penPalette = {
  types: {
    none: 'none',
    pencil: 'pencil',
    eraser: 'eraser',
    magic: 'magic',
    magicEraser: 'magicEraser'
  },
  adders: ['pencil', 'magic'],
  removers: ['eraser', 'magicEraser'],
  colors: [ // color field must be defined #000000 -style
    { title: 'red', color: '#ff0000', },
    { title: 'lightblue', color: '#0080ff', },
    { title: 'blue', color: '#0000ff', },
    { title: 'purple', color: '#8A2BE2', },
    { title: 'green', color: '#008000', },
    { title: 'brown', color: '#A52A2A', }
  ],
  widths: [
    { title: 'fine', width: 1, },
    { title: 'thin', width: 2, },
    { title: 'medium', width: 4, },
    { title: 'thick', width: 8, },
    { title: 'eraser', width: 30, }
  ],
};
export const magicPenColor = penPalette.colors[penPalette.colors.findIndex(el => el.title === 'purple')].color;

export const getBrush = (canvas, type, width, color = "") => {
  let b;
  if (penPalette.removers.includes(type) ) {
    b = new fabric.EraserBrush(canvas);
  } else {
    b = new fabric.PencilBrush(canvas);
    b.color = color;
  }
  b.width = width;
  return b;
};