import { co } from "./diagnostics.js";

/**
 * 
 * @param {perc, array[{s = scaled, m = # mistakes, p = percent}]} - 
 * @returns scaled
 */
export function percToScaled (perc, array, diag = false) {
  perc = parseFloat(perc);

  // edge cases
  if (isNaN(perc) || perc < 0 || perc > 100 || array.length === 0) return -1;
  if (array.length === 1) return array[0].s;
  if (perc === 0)
    return parseInt(array[0].s);
  if (perc === 100)
    return parseInt(array[array.length - 1].s);

  let l = 0;
  let r = array.length - 1;

  let counter = 0;

  while (l <= r && counter < array.length) {
    let m = Math.floor((l + r) / 2);
    if (diag) co('dark', 'percToScaled', l, m, r, array[m]?.p);

    if (perc >= array[m].p && perc < array[m+1].p) {
      if (diag) co('dark', 'between m and m+1');
      return parseInt(array[m].s);
    } else if (perc >= array[m-1].p && perc < array[m].p) {
      if (diag) co('dark', 'between m-1 and m');
      return parseInt(array[m-1].s);
    } else if (perc >= array[m+1].p) {
      if (diag) co('dark', 'left side moves m+1');
      l = m + 1;
    } else if (perc < array[m-1].p) {
      if (diag) co('dark', 'right side moves m-1');
      r = m - 1;
    }
    counter ++;
  }
  return -1;
}
/**
 * curvetype === 'percent'
 * @param {*} perc 
 * @param {*} curveAmount 
 * @returns 
 */
export function addCurvePercent (perc, curveAmount) {
  perc = parseFloat(perc) + parseInt(curveAmount);
  if (perc > 100) perc = 100;
  else if (perc < 0) perc = 0;
  return perc;
}

/**
 * curvetype === 'scaled'
 * @param {*} scaled 
 * @param {*} curveAmount 
 * @param {*} maxScore 
 * @returns 
 */
export function addCurveScaled (scaled, curveAmount, maxScore) {
  scaled = parseInt(scaled) + parseInt(curveAmount);
  if (scaled > maxScore) scaled = maxScore;
  else if (scaled < 0) scaled = 0;
  return scaled;
}