// import Axios from 'axios-observable';
import { ax } from '@/assets/axios.js';
import { parseFairlightCookie } from "@/assets/CookieFunctions.js";
import { userLevelsForTarget } from "@/constants/users.js";
import { co } from '@/assets/diagnostics.js';

const checkIfCookieExists = (cookieName) => {
  const cookieArr = document.cookie.split(";");
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split("=");
    if (cookieName == cookiePair[0].trim()) {
      return true;
    }
  }
  return false;
}

const cookieName = process.env.VUE_APP_AUTH_COOKIE_NAME +
  '_' +
  process.env.VUE_APP_TARGET;

export default {
  namespaced: true,
  state() {
    return {
      loginBaseUrl: '',
      loggedIn: false,
      localAuthdataKey: '',
    };
  },
  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    loginBaseUrl(state) {
      return state.loginBaseUrl;
    },
  },
  actions: {
    setLocalAuthdataKey(context, key) {
      context.commit('setLocalAuthdataKey', key);
    },
    setLoginBaseUrl(context, url) {
      context.commit('setLoginBaseUrl', url);
    },
    setLoggedIn(context, loggedIn) {
      context.commit('setLoggedIn', loggedIn);
    },
    login(context, loginData) { // loginData: email, password

      return new Promise((resolve, reject) => {
        const pl = {
          email: loginData.email,
          password: loginData.password,
        };
        const loginUrl = context.state.loginBaseUrl + 'auth/login';
        if (process.env.VUE_APP_TARGET != undefined &&
          userLevelsForTarget[process.env.VUE_APP_TARGET] != undefined
        ) {
          pl.allowedLevels = userLevelsForTarget[process.env.VUE_APP_TARGET];
        }

        let newLoggedInValue = false;

        ax(loginUrl, pl).then(
          (response) => { 
            if (response.data.logged_in == true) {
              newLoggedInValue = true;
            }
            context.commit('setLoggedIn', newLoggedInValue);

            const localSavePayload = {
              cookieName: cookieName,
              expires: parseInt(response.data.expires) * 1000,
            };
            context.dispatch('localSaveLogin', JSON.stringify(localSavePayload));

            resolve(response.data);
          },
          (error) => { 
            context.commit('setLoggedIn', newLoggedInValue);
            co('danger', 'login', error);
            reject(error);
          },
        );

      });
    },
    logout(context) {
      return new Promise((resolve, reject) => {

        context.dispatch("resetAllStores", null, {root: true});

        const logoutUrl = context.state.loginBaseUrl + 'auth/logout';

        ax(logoutUrl).then(
          (response) => { 
            context.commit('setLoggedIn', false);
            context.dispatch('localDeleteLogin');
            resolve(response.data);
          },
          (error) => { 
            context.commit('setLoggedIn', false);
            context.dispatch('localDeleteLogin');
            co('danger', 'login', error);
            reject(error);
          },
        );

      });
    },
    amiin(context) {
      return new Promise((resolve, reject) => {
        const amiUrl = context.state.loginBaseUrl + 'amiin';

        ax(amiUrl).then(
          (response) => { 
            resolve(response.data.logged_in == 1);
          },
          (error) => { 
            co('danger', error); 
            reject(error);
          },
        );

      }); // promise
    },
    localSaveLogin(context, payload) {
      localStorage.setItem(context.state.localAuthdataKey, payload);
    },
    localLoadLogin(context) {
      return new Promise((resolve, reject) => {

        let loginSuccess = false;

        if (localStorage.getItem(context.state.localAuthdataKey) !== null) {
          const localData = JSON.parse(localStorage.getItem(context.state.localAuthdataKey));
          co('handler', 'attempting local load', localData);

          const aMinuteFromNow = Date.now() + 60000;
          if (localData.expires >= aMinuteFromNow && checkIfCookieExists(localData.cookieName)) {

            context.dispatch("amiin").then(
              (response) => {
                if (response === true) {
                  loginSuccess = true;
                  context.commit('setLoggedIn', true);
                  context.dispatch("me/setMeDataFromCookie", null, { root: true, });
                  resolve(loginSuccess);
                } 
              },
              (error) => { 
                co("warn", error); 
                reject(error); 
              },
            );
          } else {
            resolve (loginSuccess);
          }
        } else {
          resolve (loginSuccess)
        }
      });
    },
    async crossLoginUsingCookie(context) {
      return new Promise((resolve, reject) => {

        let loginSuccess = false;
        const tsNow = Date.now();

        if (checkIfCookieExists(cookieName)) {

          const toleranceMs = 5000; // how many ms after backend cookie creation this code must be run! (security)
          const cookieData = parseFairlightCookie();
          const loginUntil = (cookieData.ts_request) + toleranceMs;
          co('info', 'cross login cookie data', {
            ts_request: cookieData.ts_request,
            loginUntil: loginUntil,
            tsNow: tsNow,
          });     

          if (loginUntil >= tsNow) {

            context.dispatch("amiin").then(
              (response) => {
                if (response === true) {

                  const localSavePayload = {
                    cookieName: cookieName,
                    expires: parseInt(cookieData.exp) * 1000,
                  };
                  context.dispatch('localSaveLogin', JSON.stringify(localSavePayload));
                  
                  loginSuccess = true;
                  context.commit('setLoggedIn', true);
                  context.dispatch("me/setMeDataFromCookie", null, { root: true, });
                  resolve(loginSuccess);
                } else {
                  co("error", 'crossLoginUsingCookie', 'amiin failed');
                }
              },
              (error) => { 
                co("warn", error); 
                reject(error); 
              },
            );

          } else {
            co("error", 'crossLoginUsingCookie', 'tolerance exceeded', {
              tsNow,
              loginUntil,
            }); 
            resolve (loginSuccess);
          }
        } else {
          co("error", 'crossLoginUsingCookie', 'cookie does not exist');
          resolve (loginSuccess);
        }
      });
    },
    localDeleteLogin(context) {
      co('handler', 'deleting local auth data');
      localStorage.removeItem(context.state.localAuthdataKey);
    },
  },
  mutations: {
    setLocalAuthdataKey(state, key) {
      state.localAuthdataKey = key;
    },
    setLoginBaseUrl(state, url) {
      state.loginBaseUrl = url;
    },
    setLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
  },
};