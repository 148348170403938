<template>
  <ion-menu :id="menuId" 
    :menu-id="menuId" 
    :content-id="contentId" 
    :swipe-gesture="swipeGesture"
    side="end">
    <ion-header>
      <ion-toolbar>
        <ion-title v-if="menuTitle !== ''">{{ menuTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item v-for="(m, mindex) in menuItems" :key="mindex"
          :disabled="m.disabled"
          button @click="menuNavigation(m.link)">
          <ion-icon v-if="m.icon !== undefined" :icon="m.icon" slot="start"></ion-icon>
          <ion-label>{{ m.title }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script>

import {
  IonMenu,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonItem,
  IonList,
  IonLabel,
  IonIcon,
  menuController,
} from "@ionic/vue";

export default {
  components: {
    IonMenu,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonItem,
    IonList,
    IonLabel,
    IonIcon,
  },
  props: {
    menuId: {
      type: String,
      required: true,
    },
    contentId: {
      type: String,
      required: true,
    },
    menuTitle: {
      type: String, 
      required: false,
      default: '',
    },
    menuItems: {
      type: Array, // { icon?, title, link }
      required: true,
    },
    swipeGesture: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    menuNavigation(url) {
      menuController.close(this.menuId);
      this.$router.replace(url);
    },
  },
};
</script>