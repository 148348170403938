import { ax } from "@/assets/axios.js";
import { co } from '@/assets/diagnostics.js';

export default {
  namespaced: true,
  state() {
    return {
      offsetMinutes: 0,
    };
  },
  getters: {
    offsetMinutes(state) {
      return state.offsetMinutes;
    },
  },
  actions: {
    fetchOffsetMinutes(context) {
      const url = context.rootGetters['auth/loginBaseUrl'];
      ax(url + 'tz/getoffset').then(
        (response) => {
          context.commit('setOffsetMinutes', Math.floor(response.data.offset_seconds / 60));
        },
        (error) => { co('danger', error); },
      );
    },
  },
  mutations: {
    setOffsetMinutes(state, payload) {
      state.offsetMinutes = payload;
    },
  },
};