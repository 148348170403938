import store from '@/store';

export function checkLoggedIn(to, from, next) {
  if (!store.getters['auth/loggedIn']) next('/login');
  // else if (!store.getters['me/isStudent']) next('/restricted');
  else next();
}

export function teacherGuard(to, from, next) {
  if (!store.getters['auth/loggedIn']) next('/login');
  else if (!store.getters['me/isTeacher']) next('/restricted');
  else next();
}

export function managerGuard(to, from, next) {
  if (!store.getters['auth/loggedIn']) next('/login');
  else if (!store.getters['me/isManager']) next('/restricted');
  else next();
}