import { errorsend } from './errorsend.js';

const outputtable = ["boolean", "number", "string", "bigint", "symbol"];
const errorStyles = ['error', 'danger', 'warn', 'warning'];

export function co(arg1_style, arg2_message, ...args) {

  let message, style;
  if (arg2_message === undefined) {
    message = arg1_style;
    style = "info";
  } else {
    style = arg1_style;
    message = arg2_message;
  }

  const isErrorMsg = errorStyles.includes(style);

  // app is "live" and it's not an error message - quit
  if (process.env.VUE_APP_DIAG != "1" && !isErrorMsg) 
    return false;

  let bg, col;
  if (style == "primary") {
    bg = "#0020C2";
    col = "#FFFFFF";
  } else if (style == "success") {
    bg = "#009900";
    col = "#FFFFFF";
  } else if (style == "error" || style == "danger") {
    bg = "#660000";
    col = "#FFFFFF";
  } else if (style == "warn" || style == "warning") {
    bg = "#E67451";
    col = "#FFFFFF";
  } else if (style == "handler") {
    bg = "#045F5F";
    col = "#FFFFFF";
  } else if (style == "detail") {
    bg = "#FFFFFF";
    col = "grey";
  } else if (style == "dark") {
    bg = "#0C090A";
    col = "565051";
  } else { // like primary
    bg = "#0020C2";
    col = "#FFFFFF";
  }

  const d = new Date();
  const t = `${d.getUTCFullYear()}/${d.getUTCMonth() + 1}/${d.getUTCDate()}`;
  const css = `
    font-family: verdana;
    font-size: 1.1em;
    background: ${bg}; color: ${col};
    padding: 2px;
  `;
  const m = `%c ${t} ${message}`;
  console.log(m, css, ...args);
  if (!outputtable.includes(typeof(message))) {
    console.log(message);
  }

  // app is "live" and it's an error message - send msg to server
  if (isErrorMsg && process.env.VUE_APP_DIAG != "1") {
    const esLocator = message;
    let esMsg = '';
    let esData = null;

    if (args.length > 0) {
      let cloned = structuredClone(args);
      const first = cloned.shift();
      if (first !== null)
        esMsg = first;
        
      if (cloned.length === 1)
        esData = cloned[0];
      else if (cloned.length > 1)
        esData = cloned;
    }
    
    errorsend(esLocator, esMsg, esData);
  }
  
}