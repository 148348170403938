import { ax } from "./axios.js";
import { co } from "./diagnostics.js";

export function errorsend(locator, message, dataObj = null) {
  try {
    // const toJson = (input) => {
    //   if (
    //     input !== '' && 
    //     input !== null && 
    //     (typeof input === 'object' || Array.isArray(input))
    //   ) {
    //     return JSON.stringify(input);
    //   }
    //   return input;
    // }
    // message = toJson(message);
    // dataObj = toJson(dataObj);
    
    const pl = {
      locator,
      message,
      dataObj,
    };
    const url = process.env.VUE_APP_AUTH_BASE_URL + 'errorbox/savefromfront';
    ax(url, pl).then(
      () => {
        //
      },
      (error) => {
        co('dark', 'errorsend', error);
      },
    );
  } catch (e) {
    co('dark', 'errorsend', e);
  }
}