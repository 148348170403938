import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
import { decodeBackend } from '@/assets/encode.js';

const ur = {
  /**
   * tests: {
   *  scheduledTestId: { // simple tests
   *    scheduledTest: {},
   *    testSets: [
   *      {
   *        test_set: {},
   *        resources: [],
   *        questions: [],
   *      },
   *    // testSet: {}, // obsolete
   *    // questions: [], // obsolete
   *  }
   *  scheduledTestId: { // digital
   *    scheduledTest: {},
   *    testSets: [
   *      {
   *        test_set: {},
   *        resources: [],
   *        questions: [],
   *      },
   *      ...
   *    ]
   *  }
   * }
   */
  tests: {},
  latestScheduledTestId: 0,
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    tests(state) { return state.tests; },
    latestScheduledTestId(state) { return state.latestScheduledTestId; },
    currentTest(state) {
      if (state.tests[state.latestScheduledTestId] == undefined)
        return false;
      return state.tests[state.latestScheduledTestId];
    },
  },
  actions: {
    fetchTest(context, pl) { // pl: { scheduledTestId: , digital: bool, force?: bool, }

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        let suffix = "loadsimple";
        if (pl.digital)
          suffix = "loaddigital";

        ax(url + `stake/${suffix}`, pl).then(
            (response) => {

              const decoded = decodeBackend(response.data.enc);
              let saveData;
              if (pl.digital) { // digital test
                saveData = {
                  scheduledTestId: decoded.scheduled_test_data.id,
                  data: {
                    scheduledTest: decoded.scheduled_test_data,
                    // testdata: decoded.testset_data,
                    testSets: decoded.testset_data,
                  },
                }
              } else { // simple test
                saveData = {
                  scheduledTestId: decoded.basic_test_data.id,
                  data: {
                    scheduledTest: decoded.basic_test_data,
                    // testSet: decoded.test_set_data,
                    testSets: decoded.testset_data,
                    // questions: decoded.question_data,
                  },
                };
              }
              context.commit('addTest', saveData);
              resolve();
            },
            (error) => {
              co("error", "fetchTest", error);
              reject(error);
            }
        );
      });
    },
    setLatestScheduledTestId(context, pl) {
      context.commit("setLatestScheduledTestId", pl);
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    addTest(state, pl) {
      state.tests[pl.scheduledTestId] = pl.data;
    },
    setLatestScheduledTestId(state, pl) {
      state.latestScheduledTestId = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};
