<template>
  <ion-page>
    <ion-content :scroll-y="false">
      <ion-tabs>
        <menu-abstract
          v-for="(m, mindex) in myMainMenus"
          :key="mindex"
          content-id="tab-content"
          :menu-id="m.id"
          :menu-title="m.title"
          :menu-items="m.items"
        ></menu-abstract>

        <ion-router-outlet id="tab-content"></ion-router-outlet>
        <ion-tab-bar slot="bottom">
          <ion-tab-button
            v-for="(t, tindex) in myMainTabs"
            :key="tindex"
            :tab="t.tab"
            :href="t.href"
          >
            <ion-icon :icon="t.icon"></ion-icon>
            <ion-label>{{ t.title }}</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonContent,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from "@ionic/vue";

import MenuAbstract from "./MenuAbstract.vue";
// constants.js defines what nav tabs and right-hand menus exist
import MenusTabs from "@/mixins/MenusTabs.js";

export default {
  mixins: [MenusTabs],
  components: {
    IonContent,
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonPage,
    IonRouterOutlet,
    MenuAbstract,
  },

};
</script>