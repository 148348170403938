import { ax } from '@/assets/axios.js';
import { penPalette } from '@/assets/pens.js';

const initialPenColor = penPalette
  .colors[penPalette.colors.findIndex(el => el.title === 'lightblue')]
  .color;
const initialPenWidth = penPalette
  .widths[penPalette.widths.findIndex(el => el.title === 'medium')]
  .width;

const ur = {
  type: 'none', // none, pencil, eraser, magic, magicEraser (defined in penPalette)
  color: initialPenColor, 
  selectedRegularColor: initialPenColor,
  selectedRegularWidth: initialPenWidth,
  width: 1,
  magicInkLoadedTime: '',
  latestMagicInk: {},
  latestFabricWidth: 0,
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    type(state) { return state.type; },
    color(state) { return state.color; },
    selectedRegularColor(state) { return state.selectedRegularColor; },
    selectedRegularWidth(state) { return state.selectedRegularWidth; },
    width(state) { return state.width; },
    magicInkLoadedTime(state) { return state.magicInkLoadedTime; },
    latestMagicInk(state) { return state.latestMagicInk; },
    latestFabricWidth(state) { return state.latestFabricWidth; },
  },
  actions: {
    setType(context, pl) {
      context.commit("setType", pl);
    },
    setColor(context, pl) {
      context.commit("setColor", pl);
    },
    setSelectedRegularColor(context, pl) {
      context.commit("setSelectedRegularColor", pl);
      if (context.getters.type === penPalette.types.pencil) {
        context.commit("setColor", pl);
      }
    },
    setSelectedRegularWidth(context, pl) {
      context.commit("selectedRegularWidth", pl);
      if (context.getters.type === penPalette.types.pencil) {
        context.commit("setWidth", pl);
      }
    },
    setWidth(context, pl) {
      context.commit("setWidth", pl);
    },
    setPenAttributes(context, pl) { // pl: { type?:, color?:, width?: },
      context.commit("setPenAttributes", pl);
      if (pl.type === penPalette.types.pencil) {
        context.commit("setColor", context.getters.selectedRegularColor);
        context.commit("setWidth", context.getters.selectedRegularWidth);
      }
    },
    setLatestFabricWidth(context, pl) {
      context.commit("setLatestFabricWidth", pl);
    },
    resetAll(context) {
      context.commit("resetAll");
    },
    // pl = { testSetId }
    loadMagicInk(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'ink/load', pl).then(
          (response) => { 
            if (parseInt(response.data.ink_count) === 0) {
              context.commit("setLatestMagicInkAll", {});
            } else {
              context.commit("setLatestMagicInkAll", response.data.ink_data);
            }
            context.commit("setMagicInkLoadedTime", Date.now());
            resolve();
          },
          (error) => { reject(error); },
        );
      });
    },
    // pl = { testSetId, }
    saveMagicInk(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        pl.inkData = context.getters.latestMagicInk;

        // compute how many questions have ink - this is an estimated number - i'll take the highest number among inked fields only to keep it light
        let numInked = 0;
        for (const field in pl.inkData) {
          if (typeof pl.inkData[field] === 'object' && pl.inkData[field] !== null) {
            const n = Object.keys(pl.inkData[field]).length;
            if (n > numInked) {
              numInked = n;
            }
          }
        }
        pl.numInked = numInked;

        ax(url + 'ink/save', pl).then(
          () => { 
            context.dispatch("loadMagicInk", { testSetId: pl.testSetId, });
            resolve();
          },
          (error) => { reject(error); },
        );
      });
    },
  },
  mutations: {
    setType(state, pl) {
      state.type = pl;
    },
    setColor(state, pl) {
      state.color = pl;
    },
    setSelectedRegularColor(state, pl) {
      state.selectedRegularColor = pl;
    },
    selectedRegularWidth(state, pl) {
      state.selectedRegularWidth = pl;
    },
    setWidth(state, pl) {
      state.width = pl;
    },
    setPenAttributes(state, pl) {
      Object.assign(state, pl);
    },
    setMagicInkLoadedTime(state, pl) {
      state.magicInkLoadedTime = pl;
    },
    setLatestMagicInkAll(state, pl) {
      state.latestMagicInk = pl;
    },
    setLatestMagicInkField(state, pl) { // pl: { canvasData:, inkFieldName: }
      state.latestMagicInk[pl.inkFieldName] = pl.canvasData;
    },
    setLatestFabricWidth(state, pl) {
      state.latestFabricWidth = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};