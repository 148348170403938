export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  try {
    const res = JSON.parse(jsonPayload);
    return res;
  } catch(e) {
    return false;
  }
};

export const parseFairlightCookie = () => {
  const cookieName = 
    process.env.VUE_APP_AUTH_COOKIE_NAME + 
    '_' + 
    process.env.VUE_APP_TARGET;

  const fairlightCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(cookieName + "="))
    .split("=")[1];

  return parseJwt(fairlightCookie);
};