import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
import { decodeBackend } from '@/assets/encode.js';

const ur = {
  /**
   * results: {
   *    studentId: {
   *      scheduledTestId: {
   *        answerSets: [],
   *        basic: {},
   *        testSet: {},
   *      }
   *    }
   * },
   */
  results: {},
  // latest .. to determine which to load as "current"
  latestStudentId: 0,
  latestScheduledTestId: 0,
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    latestStudentId(state) {
      return state.latestStudentId;
    },
    results(state) {
      return state.results;
    },
    currentResult(state) {
      if (state.latestStudentId === 0 || state.latestScheduledTestId === 0)
        return false;
      const res = state.results[state.latestStudentId]?.[state.latestScheduledTestId];
      if (!res)
        return false;
      return res;
    },
  },
  actions: {
    loadTestResult(context, pl) { // pl: { scheduledTestId: , studentId?: , }

      if (!pl.studentId) {
        pl.studentId = context.rootGetters["me/id"];
      }

      context.commit('setLatest', {
        studentId: pl.studentId,
        scheduledTestId: pl.scheduledTestId,
      });

      if (context.getters.results[pl.studentId]?.[pl.scheduledTestId] != undefined)
        return;

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'sscore/loadtestresult', pl).then(
          (response) => { 

            context.commit('addToResults', {
              studentId: pl.studentId,
              scheduledTestId: pl.scheduledTestId,
              data: response.data,
            });
            resolve();
          },
          (error) => { 
            reject(error); 
            co('danger', error)
          },
        );
      });
    },
    resetAll(context) {
      context.commit('resetAll');
    },
  },
  mutations: {
    setLatest(state, pl) {
      state.latestStudentId = pl.studentId;
      state.latestScheduledTestId = pl.scheduledTestId;
    },
    addToResults(state, pl) {
      if (state.results[pl.studentId] == undefined)
        state.results[pl.studentId] = {};

      state.results[pl.studentId][pl.scheduledTestId] = {
        answerSets: decodeBackend(pl.data.result_data),
        basic: decodeBackend(pl.data.test_basic_data),
        testSet: decodeBackend(pl.data.test_set_data),
      }
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};


