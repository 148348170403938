const ur = {
  digitalTypes: [],
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    digitalTypes(state) {
      return state.digitalTypes;
    },
  },
  actions: {
    setDigitalTypes(context, pl) {
      context.commit("setDigitalTypes", pl);
      // console.log('set digi t', pl, context.getters.digitalTypes);
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setDigitalTypes(state, pl) {
      state.digitalTypes = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};