import { ax } from '@/assets/axios.js';
import { co } from '@/assets/diagnostics.js';
import { decodeBackend } from '@/assets/encode.js';

const ur = {
  open: [],
  results: [],
  // take test
  ttTest: null,
  ttInstances: [],
};

export default {
  namespaced: true,
  state() {
    return { ...structuredClone(ur), };
  },
  getters: {
    open(state) { return state.open; },
    results(state) { return state.results; },
    ttTest(state) { return state.ttTest; },
    ttInstances(state) { return state.ttInstances; },
  },
  actions: {
    // pl: { offset: , period: 'current' / 'past', concat:  }
    fetchVocabTests(context, pl) {

      let action = 'set';
      if (pl.concat === true) action = 'addTo';
      let list = 'Open'; // period == current
      if (pl.taken === true) list = 'Results';

      // only perform 'set' on an existing dataset if 'pl.force' is true
      if (action === 'set') {
        const len = context.getters[list.toLowerCase()].length;
        if (len > 0 && pl.force !== true)
          return;
      }

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'svocab/loadlist', pl).then(
          (response) => { 
            context.commit(action + list, decodeBackend(response.data.v_data));
            // co('handler', response.data)
            // co('handler', decodeBackend(response.data.v_data));
            const ret = {
              rows: decodeBackend(response.data.v_data).length,
              perpage: response.data.perpage,
            };
            resolve(ret);
          },
          (error) => { 
            co("error", "fetchVocabTests", error);
            reject(error); 
          },
        );
      });
    },
    fetchVocabTest(context, vtid) {
      context.dispatch("resetCurrent");
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'svocab/loadtest', { vtid: vtid, }).then(
          (response) => { 
            context.commit("setTtTest", decodeBackend(response.data.vt_data));
            resolve();
          },
          (error) => { 
            co("error", "fetchVocabTest", error);
            reject(error); 
          },
        );
      });
    },
    // this needs to be run when the vocab test page opens to check for existing instances
    initVocabTest(context, vtid) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'svocab/inittest', { vtid: vtid, }).then(
          (response) => { 
            context.commit("setTtInstances", decodeBackend(response.data.instance_data));
            resolve();
          },
          (error) => { 
            co("error", "initVocabTest", error);
            reject(error); 
          },
        );
      });
    },
    // pl { vtid:, wordIds: [],  }
    saveInstance(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'svocab/saveinstance', pl).then(
          (response) => { 
            context.dispatch('initVocabTest', pl.vtid);
            resolve(response.data);
          },
          (error) => { 
            co("error", "saveInstance", error);
            reject(error); 
          },
        );
      });
    },
    // pl { testInstanceId: , studentInput: { wordId, answer, orig }  }
    saveVocabTestAttempt(context, pl) {
      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'svocab/saveattempt', pl).then(
          (response) => { 
            co('handler', 'saveattempt', response.data);
            resolve(response.data);
          },
          (error) => { 
            co("error", "saveVocabTestAttempt", error);
            reject(error); 
          },
        );
      });
    },
    resetCurrent(context) {
      context.commit("setTtTest", null);
      context.commit("setTtInstances", []);
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    setOpen(state, pl) {
      state.open = pl;
    },
    addToOpen(state, pl) {
      state.open = state.open.concat(pl);
    },
    setResults(state, pl) {
      state.results = pl;
    },
    addToResults(state, pl) {
      state.results = state.results.concat(pl);
    },
    setTtTest(state, pl) {
      state.ttTest = pl;
    },
    setTtInstances(state, pl) {
      state.ttInstances = pl;
    },
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
  },
};