<template>
  <ion-app>
    <ion-router-outlet id="main-content" v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </ion-router-outlet>
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";

import { debounce, setFavicon } from "@/assets/utils.js";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
    };
  },
  created() {
    document.title = process.env.VUE_APP_TITLE;
    setFavicon(process.env.VUE_APP_ICON);

    if (process.env.VUE_APP_ENFORCE_TLS === "1") {
      if (location.protocol === 'http:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }

    this.$store.dispatch('auth/setLoginBaseUrl', process.env.VUE_APP_AUTH_BASE_URL);
    this.$store.dispatch('auth/setLocalAuthdataKey', process.env.VUE_APP_LOCAL_AUTHDATA_KEY);
    this.$store.dispatch('tz/fetchOffsetMinutes');
    this.$store.dispatch('fetchActivated');
    try {
      const digitalTypes = JSON.parse(process.env.VUE_APP_DIGITAL_TEST_TYPES);
      this.$store.dispatch('supportedTests/setDigitalTypes', digitalTypes);
    } catch (e) {
      console.error(e);
    }

    this.setRealVH();
    const debouncedSetVH = debounce(this.setRealVH, 150);
    window.addEventListener('resize', debouncedSetVH);
    document.ondblclick = (e) => { e.preventDefault(); };
  },
  methods: {
    setRealVH() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>