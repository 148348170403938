import { checkLoggedIn } from "@/router/guards.js";

export const studentTabs = [
  {
    path: 'stats',
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/tabs_student/StatsOverview.vue'),
  },
  {
    path: 'tl/:listname',
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/tabs_student/TestListStudentTab.vue'),
  },
  {
    path: 'vl/:listname',
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/tabs_student/VocabListTab.vue'),
  },
];

export const studentPages = [
  // student paths
  {
    path: '/ttake', // scheduled_test_id passed via $store
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/StudentTestTakePage.vue'),
  },  
  {
    path: '/tviewdigital', // scheduled_test_id passed via $store
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/StudentTestTakePage.vue'),
  },  
  {
    path: '/tsubmit', // scheduled_test_id passed via $store
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/TestSubmitPage.vue'),
  },  
  {
    path: '/tresult', // scheduled_test_id passed via $store
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/TestResultPage.vue'),
  },  
  {
    path: '/stats/:ttid',
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/StatsTestType.vue'),
  },
  {
    path: '/vtake',
    beforeEnter: [checkLoggedIn],
    component: () => import('@/pages/VocabTakePage.vue'),
  },
];
