import { ax } from "@/assets/axios.js";
import { decodeBackend } from "@/assets/encode.js";
import { objIsEmpty } from '@/assets/utils.js';
import { co } from '@/assets/diagnostics.js';

/**
 * scheduledTestResults = [
 *  {
 *    scheduledTest: {},
 *    testSet: {},  <- this is fed into the testDisplay component
 * 
 *    >> CHANGE:
 *    testSets: [
 *      {
 *        questions: [],
 *        resources: [],
 *        test_set: {},
 *      }
 *    ]
 * 
 *  }
 * ]
 * 
 * answerSetsForTest = [  <- saving answer sets for several scheduled tests!
 *  {
 *    scheduledTestId: int,
 *    data: [
 *      answer_sets: [],
 *      questions: [],
 *    ],
 *  }
 * ]
 * 
 * whoDidWhat = {
 *  scheduledTestId: [
 *    questionId: int,
 *    data: []
 *  ]
 * }
 */

const ur = {
  assignedTestTypes: {},
  assignedWorkbookTypes: {},
  tests: [],
  workbookAssignments: [],
  searchedTests: [],
  searchParams: {}, // save whenever a search is run
  scheduledTestResults: [],
  currentResultIdx: -1,
  answerSetsForTest: [],
  currentAnswerSetsIdx: -1,
  whoDidWhat: {}, // save for all loaded tests (see structure above)
  currentWhoDidWhatQuestionIdx: -1,
  showNicknamesOnly: false,
};

export default {
  namespaced: true,
  state() {
    return {
      ...structuredClone(ur),
    };
  },
  getters: {
    assignedTestTypes(state) {
      return state.assignedTestTypes;
    },
    assignedWorkbookTypes(state) {
      return state.assignedWorkbookTypes;
    },
    tests(state) {
      return state.tests;
    },
    workbookAssignments(state) {
      return state.workbookAssignments;
    },
    searchedTests(state) {
      return state.searchedTests;
    },
    searchParams(state) {
      return state.searchParams;
    },
    scheduledTestResults(state) {
      return state.scheduledTestResults;
    },
    currentScheduledTest(state) {
      if (state.scheduledTestResults[state.currentResultIdx] == undefined)
        return false;
      return state.scheduledTestResults[state.currentResultIdx];
    },
    answerSetsForTest(state) {
      return state.answerSetsForTest;
    },
    currentAnswerSetsForTest(state) {
      if (state.answerSetsForTest[state.currentAnswerSetsIdx] == undefined)
        return false;
      return state.answerSetsForTest[state.currentAnswerSetsIdx];
    },
    whoDidWhat(state) {
      return state.whoDidWhat;
    },
    currentWhoDidWhat(state) {
      if (state.scheduledTestResults[state.currentResultIdx] == undefined)
        return false;
      const stId = state.scheduledTestResults[state.currentResultIdx].scheduledTest.id;
      if (state.whoDidWhat[stId] == undefined)
        return false;
      if (state.whoDidWhat[stId][state.currentWhoDidWhatQuestionIdx] == undefined)
        return false;
      return state.whoDidWhat[stId][state.currentWhoDidWhatQuestionIdx];
    },
    showNicknamesOnly(state) {
      return state.showNicknamesOnly;
    },
  },
  actions: {
    // pl: {forceReload}
    loadAssignedTestTypes(context, pl) {
      if (pl?.forceReload !== true &&
        ( !objIsEmpty(context.getters.assignedTestTypes) || 
          !objIsEmpty(context.getters.assignedWorkbookTypes) )
      )
        return;

      return new Promise((resolve, reject) => {
        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'tch/test/gettesttypesforteacher').then(
          (response) => { 
            const decoded = decodeBackend(response.data.enc);
            context.commit("setAssignedTestTypes", decoded.tt_data);
            context.commit("setAssignedWorkbookTypes", decoded.tt_data_wb);
            resolve();
          },
          (error) => { 
            co("error", "loadTests", error);
            reject(error); 
          },
        );
      });
    },
    // pl: { forceReload: Bool, concat: Bool, data: { perpage, offset, ttid, search, isWorkbook } }
    loadTests(context, pl) {
      return new Promise((resolve, reject) => {

        let concat = false; // infinite scroller?
        if (pl.concat !== undefined && pl.concat === true)
          concat = true;

        let search = false;
        // this is a search if pl.search is set OR if a ttid is set
        if ((pl.data.search != undefined && pl.data.search.length > 0) || pl.data.ttid != undefined) {
          search = true;
          context.commit("setSearchParams", pl.data);
        }

        let scheduledTestTypes = [1, 4]; // 1 - standard, 2 - retake, 4 - remix
        let wb = false;
        if (pl.data.isWorkbook != undefined && pl.data.isWorkbook === true)
          wb = true;

        if (!pl.forceReload && !concat) {
          if (
            (!wb && context.getters.tests.length > 0) ||
            (wb && context.getters.workbookAssignments.length > 0) 
          )
            return false;
        }

        pl.data.scheduledTestTypes = scheduledTestTypes;
          

        const url = context.rootGetters['auth/loginBaseUrl'];
        ax(url + 'tch/test/loadlist', pl.data).then(
          (response) => { 
            let c_action = "set";
            if (concat) c_action = "addTo";

            let c_field;
            if (!search) {
              if (!wb)
                c_field = "Tests";
              else
                c_field = "WorkbookAssignments";
            } else {
              c_field = "SearchedTests";
            }

            context.commit(c_action + c_field, response.data.test_data);
            const ret = {
              rows: response.data.test_data.length,
              perpage: response.data.perpage,
            };
            resolve(ret);
          },
          (error) => { 
            co("error", "loadTests", error);
            reject(error); 
          },
        );
      });
    },
    // pl: { forceReload: Bool, scheduledTestId, }
    loadScheduledTestResults(context, pl) {

      return new Promise((resolve, reject) => {

        const searchIdx = context.getters.scheduledTestResults
          .findIndex(el => el.scheduledTest.id == pl.scheduledTestId);
        if (searchIdx > -1) {
          context.commit("setCurrentResultIdx", searchIdx);
          resolve();
          return;
        }

        const url = context.rootGetters['auth/loginBaseUrl'];
        const out = {
          scheduledTestId: pl.scheduledTestId
        };
        ax(url + 'tch/test/loadscheduledtest', out).then(
          (response) => { 
            const decoded = decodeBackend(response.data.enc);
            const insertData = {
              scheduledTest: decoded.scheduled_test_data,
              testSets: decoded.test_set_data,
            };
            context.commit("addScheduledTestResult", insertData);
            resolve();
          },
          (error) => { 
            co("error", 'loadScheduledTestResults', error);
            reject(error); 
          },
        );
      });
    },
    // pl: { forceReload: Bool, scheduledTestId, }
    loadAnswerSetsForTest(context, pl) {
      return new Promise((resolve, reject) => {

        const searchIdx = context.getters.answerSetsForTest
          .findIndex(el => el.scheduledTestId == pl.scheduledTestId);
        if (searchIdx > -1) {
          context.commit("setCurrentAnswerSetsIdx", searchIdx);
          resolve();
          return;
        }

        const url = context.rootGetters['auth/loginBaseUrl'];
        const out = {
          scheduledTestId: pl.scheduledTestId
        };
        ax(url + 'tch/test/loadanswersets', out).then(
          (response) => { 
            let decoded = decodeBackend(response.data.enc);
            if (decoded.answer_set_data?.answer_sets != undefined) {
              for (let i=0; i<decoded.answer_set_data.answer_sets.length; i++) {
                decoded.answer_set_data.answer_sets[i].includeInDisplays = true;
              }
            }
            const insertData = {
              scheduledTestId: pl.scheduledTestId,
              data: decoded.answer_set_data,
            };
            context.commit("addAnswerSetsForTest", insertData);
            resolve();
          },
          (error) => { 
            co("error", 'loadAnswerSetsForTest', error);
            reject(error); 
          },
        );
      });
    },
    setAnswerSetIncludeInDisplays(context, pl) { // pl: { scheduledTestId, answerSetId, includeInDisplays }
      context.commit("setAnswerSetIncludeInDisplays", pl);
    },
    // pl: { questionId, scheduledTestId, qbyq: Bool } 
    // qbyq : question by question. review mode shows one question each page and needs the index to move accordingly
    loadWhoDidWhat(context, pl) {
      return new Promise((resolve, reject) => {

        const www = context.getters.whoDidWhat;

        const searchIdx = www[pl.scheduledTestId]?.findIndex(el => {
          return (
            el.questionId == pl.questionId &&
            el.scheduledTestId == pl.scheduledTestId
          );
        });

        let qbyq = false;
        if (pl.qbyq === true) qbyq = true;

        if (searchIdx > -1) {
          if (qbyq === true)
            context.commit("setCurrentWhoDidWhatQuestionIdx", searchIdx);
          resolve();
          return;
        }

        const url = context.rootGetters['auth/loginBaseUrl'];
        const out = {
          questionId: pl.questionId,
          scheduledTestId: pl.scheduledTestId
        };
        ax(url + 'tch/test/whodidwhat', out).then(
          (response) => { 
            const decoded = decodeBackend(response.data.enc);
            const insertData = {
              questionId: pl.questionId,
              scheduledTestId: pl.scheduledTestId,
              data: decoded.answer_data,
              qbyq,
            };
            context.commit("addWhoDidWhat", insertData);
            resolve();
          },
          (error) => { 
            co("error", 'loadWhoDidWhat', error);
            reject(error); 
          },
        );
      });
    },
    setShowNicknamesOnly(context, pl) {
      context.commit("setShowNicknamesOnly", pl);
    },
    resetAll(context) {
      context.commit("resetAll");
    },
  },
  mutations: {
    resetAll(state) {
      const clone = structuredClone(ur);
      for (const prop in clone) {
        state[prop] = clone[prop];
      }
    },
    setAssignedTestTypes(state, pl) {
      for (const tt of pl) {
        state.assignedTestTypes[tt.id] = tt;
      }
    },
    setAssignedWorkbookTypes(state, pl) {
      for (const tt of pl) {
        state.assignedWorkbookTypes[tt.id] = tt;
      }
    },
    setTests(state, pl) {
      state.tests = pl;
    },
    addToTests(state, pl) {
      state.tests = state.tests.concat(pl);
    },
    setWorkbookAssignments(state, pl) {
      state.workbookAssignments = pl;
    },
    addToWorkbookAssignments(state, pl) {
      state.workbookAssignments = state.workbookAssignments.concat(pl);
    },
    setSearchedTests(state, pl) {
      state.searchedTests = pl;
    },
    addToSearchedTests(state, pl) {
      state.searchedTests = state.searchedTests.concat(pl);
    },
    setSearchParams(state, pl) {
      state.searchParams = pl;
    },
    setCurrentResultIdx(state, pl) {
      state.currentResultIdx = pl;
    },
    addScheduledTestResult(state, pl) {
      const newLength = state.scheduledTestResults.push(pl);
      state.currentResultIdx = newLength - 1;
    },
    setCurrentAnswerSetsIdx(state, pl) {
      state.currentAnswerSetsIdx = pl;
    },
    addAnswerSetsForTest(state, pl) {
      const newLength = state.answerSetsForTest.push(pl);
      state.currentAnswerSetsIdx = newLength - 1;
    },
    setAnswerSetIncludeInDisplays(state, pl) { // pl: { scheduledTestId, answerSetId, includeInDisplays }
      const schIdx = state.answerSetsForTest.findIndex(
        el => el.scheduledTestId == pl.scheduledTestId
      );
      if (schIdx === -1)
        return;
      const asIdx = state.answerSetsForTest[schIdx].data?.answer_sets?.findIndex(
        el => el.answer_set_id == pl.answerSetId
      );
      if (asIdx === false || asIdx === -1)
        return;
      state.answerSetsForTest[schIdx].data.answer_sets[asIdx].includeInDisplays = pl.includeInDisplays;
    },
    addWhoDidWhat(state, pl) {
      if (state.whoDidWhat[pl.scheduledTestId] == undefined)
        state.whoDidWhat[pl.scheduledTestId] = [];
      const newLength = state.whoDidWhat[pl.scheduledTestId].push(pl);
      if (pl.qbyq === true)
        state.currentWhoDidWhatQuestionIdx = newLength - 1;
    },
    resetWhoDidWhat(state) {
      state.whoDidWhat = {};
    },
    setCurrentWhoDidWhatQuestionIdx(state, pl) {
      state.currentWhoDidWhatQuestionIdx = pl;
    },
    setShowNicknamesOnly(state, pl) {
      state.showNicknamesOnly = pl;
    },
  },
};