import Axios from 'axios-observable';

const config = {
  timeout: 15000,
};

export function ax (url, pl = {}) {
  if (pl instanceof FormData)
    pl.append("appTarget", process.env.VUE_APP_TARGET);
  else
    pl.appTarget = process.env.VUE_APP_TARGET;
  return new Promise((resolve, reject) => {
    Axios.defaults.withCredentials = true;
    Axios.post(url, pl, config)
      .subscribe({
        next: (response) => { 
          if (response.data?.error != undefined)
            reject(response.data.error);
          resolve(response);
        },
        error: (error) => { reject(error); },
    });
  });
}
