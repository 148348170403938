import { co } from '@/assets/diagnostics.js';

export class LocalStorage {

  static setDataRowForUser(fieldName, userId, rowId, data) {
    let pl = {};
    let existing = this.get(fieldName);
    if (existing) {
      if (existing[userId] == undefined)
        existing[userId] = {};
      existing[userId][rowId] = data;
      pl = existing;
    } else {
      pl[userId] = {};
      pl[userId][rowId] = data;
    }
    this.set(fieldName, pl);
  }

  static getDataRowForUser(fieldName, userId, rowId = null) {
    const allData = this.get(fieldName);
    return allData?.[userId]?.[rowId];
  }

  static deleteDataRowForUser(fieldName, userId, rowId) {
    let allData = this.get(fieldName);
    if (!allData || allData[userId] == undefined)
      return;
    if (allData[userId][rowId] != undefined) {
      delete allData[userId][rowId];
      this.set(fieldName, allData);
    }
  }

  static get(fieldName) {
    try {
      return this.dec(window.localStorage.getItem(fieldName));
    } catch(e) {
      co('danger', e);
      return false;
    }
  }

  static set(fieldName, data) {
    try {
      window.localStorage.setItem(fieldName, this.enc(data));
    } catch(e) {
      co('danger', e);
    }
  }

  static enc(data) {
    try {
      return JSON.stringify(data);
    } catch(e) {
      co('danger', e);
      return false;
    }
  }

  static dec(data) {
    try {
      return JSON.parse(data);
    } catch(e) {
      co('danger', e);
      return false;
    }
  }
}

export function packStudentAnswers(qArray) {
  let mcqData = {}; // object[qId] = [optionId, ...] 
  let frqData = {}; // object[qId] = "studentAnswer"

  for (const q of qArray) {
    if (q.mf === undefined || q.mf === "mcq") {
      const tmp = q.options
        .filter(el => {
          return (el.correct === true || el.correct === "1");
        })
        .map(el => el.id);
      if (tmp.length > 0)
        mcqData[q.id] = tmp;
    } else { // frq
      if (q.frq_answer != '')
        frqData[q.id] = q.frq_answer;
    }
  }
  return { mcqData, frqData, ts: Date.now(), };
}