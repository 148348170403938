<template>
  <menu-layout 
    :page-title="title"
    menu-id="menu-main">
    <ion-card>
      <h2>Home</h2>
    </ion-card>
  </menu-layout>
</template>

<script>
import { IonCard } from '@ionic/vue';

import MenuLayout from '../components/ui/MenuLayout.vue';

export default {
  components: {
    IonCard,
    MenuLayout,
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
    };
  },  
};
</script>

<style scoped>
</style>
