export const testLists = [
  {
    title: 'Tests',
    id: 'testopen',
    wb: false,
    comment: '',
  },
  {
    title: 'Test Results',
    id: 'testresult',
    wb: false,
    comment: '',
  },
  {
    title: 'Workbooks',
    id: 'wbopen',
    wb: true,
    comment: '',
  },
  {
    title: 'Workbook Results',
    id: 'wbresult',
    wb: true,
    comment: '',
  },
  {
    title: 'Level Tests',
    id: 'entrance',
    wb: false,
    comment: '',
  },
  {
    title: 'Personal',
    id: 'personal',
    wb: false,
    comment: '',
  },
];

export const vocabLists = [
  {
    title: 'Vocab Tests',
    id: 'totake',
  },
  {
    title: 'Vocab Results',
    id: 'results',
  },
]